import { Button } from "@mui/material";
import { ModalBuilder, Modal } from "@redriver/cinnamon-mui";
import { ActionForm } from "../ActionForm";
import { getAction, updateAction } from "./actions";

const EditAction = ({
  as: As = Button,
  fromJob,
  actionId,
  onUpdated,
  ...props
}) => (
  <ModalBuilder
    withForm
    loadAction={getAction}
    loadParams={actionId}
    loadTransform={({ status, priority, linkedEntityType, ...data }) => ({
      ...data,
      statusOption: status,
      priorityOption: priority,
      linkedEntityTypeOption: linkedEntityType,
    })}
    submitAction={updateAction}
    submitParams={actionId}
    onSubmitted={onUpdated}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Action">
        <ActionForm
          fromJob={!!fromJob}
          formProps={formProps}
          isOwner={formProps.value.isOwner}
        />
      </Modal.Edit>
    )}
  />
);

export default EditAction;
