import { useState } from "react";
import { Typography } from "@mui/material";
import { ContentBuilder, ActionBar, DataList } from "@redriver/cinnamon-mui";
import {
  HeaderContainer,
  ContentContainer,
  ContentPanel,
} from "components/containers";
import { DateTimeLabel, UserLabel } from "components/labels";
import { getAction } from "./actions";
import EditActionMenu from "./EditActionMenu";
import ListRemarks from "./ListRemarks";

const ViewAction = ({ fromJob = false, actionId, onUpdated }) => {
  const [remarkLastAdded, setRemarkLastAdded] = useState(null);
  return (
    <ContentBuilder
      loadAction={getAction}
      loadParams={actionId}
      renderContent={(action, state, events) => (
        <ContentPanel container>
          <HeaderContainer variant="border">
            <ActionBar>
              <ActionBar.Item fluid>
                <Typography variant="h6">{action.title}</Typography>
              </ActionBar.Item>
              {action.createdBy && (
                <ActionBar.Item>
                  <UserLabel prefix="Created" value={action.createdBy.label} />
                </ActionBar.Item>
              )}
              <ActionBar.Item>
                <DateTimeLabel
                  prefix={action.createdBy ? undefined : "Created"}
                  value={action.createdUtc}
                />
              </ActionBar.Item>
              <ActionBar.Item>
                <EditActionMenu
                  fromJob={fromJob}
                  action={action}
                  onUpdated={() => {
                    events.onRefresh();
                    onUpdated && onUpdated();
                  }}
                  onRemarkAdded={() => {
                    setRemarkLastAdded(Date.now());
                  }}
                />
              </ActionBar.Item>
            </ActionBar>
          </HeaderContainer>
          <ContentContainer>
            <DataList>
              <DataList.Item title="Description">
                {action.description}
              </DataList.Item>
            </DataList>
            <ListRemarks
              actionId={actionId}
              remarkLastAdded={remarkLastAdded}
            />
          </ContentContainer>
        </ContentPanel>
      )}
    />
  );
};

export default ViewAction;
