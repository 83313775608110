import { Button } from "@mui/material";
import { ModalBuilder, Modal, Format } from "@redriver/cinnamon-mui";
import { revertDocumentVersion } from "./actions";

const RevertDocumentVersion = ({
  as: As = Button,
  onReverted,
  documentId,
  version,
  ...props
}) => (
  <ModalBuilder
    submitAction={revertDocumentVersion}
    submitParams={{ documentId, versionId: version.id }}
    onSubmitted={onReverted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Revert Document Version">
        Are you sure you wish to revert this document to the previous version
        uploaded on <Format.Date value={version.createdUtc} />?
      </Modal.Confirmation>
    )}
  />
);

export default RevertDocumentVersion;
