import { Button } from "@mui/material";
import { Form, ModalBuilder, PopoverModal } from "@redriver/cinnamon-mui";
import { useRef } from "react";

const ColumnSelector = ({ columns, onSelectionChanged }) => {
  const ref = useRef(null);
  const formData = columns.reduce(
    (acc, s) => ({
      ...acc,
      [s.field]: s.show,
    }),
    {}
  );
  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <Button variant="contained" onClick={showModal} ref={ref}>
          Select Columns
        </Button>
      )}
      renderModal={(modalProps) => (
        <Form value={formData} onChange={onSelectionChanged}>
          <PopoverModal
            {...modalProps}
            size="xs"
            submitLabel="Select"
            anchorEl={ref.current}
          >
            {columns &&
              columns.map((column) => {
                return (
                  <Form.Checkbox
                    key={column.field}
                    label={column.title}
                    field={column.field}
                  />
                );
              })}
          </PopoverModal>
        </Form>
      )}
    />
  );
};

export default ColumnSelector;
