import { Button } from "@mui/material";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon-mui";
import { addRemark } from "./actions";

const AddRemark = ({ as: As = Button, actionId, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addRemark}
    submitParams={actionId}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Remark">
        <Form {...formProps}>
          <Form.TextArea
            field="remarks"
            label="Remarks"
            rows={4}
            fluid
            required
          />
        </Form>
      </Modal.Add>
    )}
  />
);

export default AddRemark;
