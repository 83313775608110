import { TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicCommodityNode from "./BasicCommodityNode";
import { Typography } from "@mui/material";

const BasicCommodityTree = ({ loading, commodities }) => {
  let commodityIds = [];
  getCommodityIds(commodities, commodityIds);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={commodityIds}
      disableSelection
    >
      {commodities?.map((c) => (
        <BasicCommodityNode key={c.id} commodity={c} />
      ))}
      {!loading && (commodities || []).length === 0 && (
        <Typography align="center" my={2}>
          No commodities found
        </Typography>
      )}
    </TreeView>
  );
};

const getCommodityIds = (commodities, commodityIds) =>
  (commodities || []).forEach((c) => {
    if (c.children?.length > 0) {
      getCommodityIds(c.children, commodityIds);
    }

    commodityIds.push(c.id);
  });

export default BasicCommodityTree;
