import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Typography } from "@mui/material";
import { Form, useApiAction, useFormField } from "@redriver/cinnamon-mui";
import StarUnselectedIcon from "assets/icons/StarEmpty";
import StarSelectedIcon from "assets/icons/StarFilled";
import { getRegionOffices } from "./actions";

const mapNodes = (regions) => {
  return regions.map((r) => ({
    value: r.id,
    label: r.name,
    ...(r.offices?.length > 0 && {
      children: r.offices.map((o) => ({
        value: o.value,
        label: <NodeDisplay office={o} />,
      })),
    }),
    disabled: r.offices?.length <= 0,
  }));
};

const NodeDisplay = ({ office }) => {
  const [defaultOfficeId, setDefaultOfficeId] = useFormField("defaultOfficeId");
  return (
    <Box display="flex" alignItems="center" key={office.value}>
      <Typography>{office.label}</Typography>
      <IconButton
        sx={{ mb: 0.5, ml: 0.5 }}
        onClick={(e) => {
          e.preventDefault();
          setDefaultOfficeId((id) =>
            office.value.toLowerCase() === id?.toLowerCase() ? "" : office.value
          );
        }}
      >
        {office.value.toLowerCase() === defaultOfficeId?.toLowerCase() ? (
          <StarSelectedIcon />
        ) : (
          <StarUnselectedIcon />
        )}
      </IconButton>
    </Box>
  );
};

const RegionOfficesCheckboxTree = (props) => {
  const [{ response }] = useApiAction(getRegionOffices, { lazy: false });

  return (
    <Form.CheckboxTree
      {...props}
      nodes={mapNodes(response ?? [])}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    />
  );
};

export default RegionOfficesCheckboxTree;
