import { ListBuilderRequestState } from "modules/mortar";
import * as actions from "mortar/endpoints/actions";
import {
  CreateActionRequest,
  CompleteActionRequest,
} from "mortar/EnergyEngineering/Endpoints/Actions";

export const getActions = ({
  filters,
  pagination,
}: ListBuilderRequestState<actions.ListActionsActionRequest>) =>
  actions.listActions({
    queryParams: {
      ...filters,
      ...pagination,
    },
  });

export const addAction = (request: CreateActionRequest) =>
  actions.createAction({
    request,
  });

export const completeAction = (
  request: CompleteActionRequest,
  actionId: string
) =>
  actions.completeAction({
    routeParams: { actionId },
    request,
  });
