import { Form, Lookup, registerLookup } from "@redriver/cinnamon-mui";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { getCertificateTypes } from "./actions";

const mapNode = (node) => {
  return {
    label: node.label,
    value: node.value,
    children:
      Array.isArray(node.children) && !!node.children.length
        ? node.children.map(mapNode)
        : null,
  };
};

const certificateTypesLookup = registerLookup(
  "certificateTypesLookup",
  getCertificateTypes,
  {
    cacheExpiry: 60 * 1000,
    transform: (data) => data.map((x) => mapNode(x)),
  }
);

const CertificateTypeCheckboxTree = (props) => (
  <Lookup
    lookup={certificateTypesLookup}
    render={({ loading, response }) =>
      !loading &&
      response && (
        <Form.CheckboxTree
          {...props}
          nodes={response}
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
        />
      )
    }
  />
);

export default CertificateTypeCheckboxTree;
