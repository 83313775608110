// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import {
    CompleteActionRequest,
    CreateActionRequest,
    UpdateActionRequest,
} from "mortar/EnergyEngineering/Endpoints/Actions";
import {
    CreateActionRemarkRequest,
    UpdateActionRemarkRequest,
} from "mortar/EnergyEngineering/Endpoints/Actions/Remarks";
import { ActionPriority, ActionStatus } from "mortar/EnergyEngineering/Enums";
import {
    ActionListView,
    ActionRemarkListView,
    ActionView,
} from "mortar/EnergyEngineering/Logic/Actions/Views";
import { PagedView } from "mortar/EnergyEngineering/Logic/Views";
import { makeAction, makeFormData } from "../lib";

export interface ListActionsActionRequest {
    queryParams: {
        pageSize: number;
        pageNumber: number;
        search: string;
        includeAllActions: boolean;
        includeAssigned: boolean;
        includeCreated: boolean;
        statuses: ActionStatus[];
        priorities: ActionPriority[];
        userIds: string[];
        jobId: string;
    };
    options?: Partial<
        ApiRequestOptions<PagedView<ActionListView>, "actions/listActions">
    >;
}

export const listActions = makeAction((
    { queryParams, options }: ListActionsActionRequest,
) => apiGet<PagedView<ActionListView>, "actions/listActions">(
    "actions/listActions",
    `actions`,
    queryParams,
    options,
), "actions/listActions");

export interface CreateActionActionRequest {
    request: CreateActionRequest;
    options?: Partial<ApiRequestOptions<string, "actions/createAction">>;
}

export const createAction = makeAction((
    { request, options }: CreateActionActionRequest,
) => apiPost<string, "actions/createAction">(
    "actions/createAction",
    `actions`,
    request,
    options,
), "actions/createAction");

export interface GetActionActionRequest {
    routeParams: {
        actionId: string;
    };
    options?: Partial<ApiRequestOptions<ActionView, "actions/getAction">>;
}

export const getAction = makeAction((
    { routeParams, options }: GetActionActionRequest,
) => apiGet<ActionView, "actions/getAction">(
    "actions/getAction",
    `actions/${routeParams.actionId}`,
    undefined,
    options,
), "actions/getAction");

export interface UpdateActionActionRequest {
    routeParams: {
        actionId: string;
    };
    request: UpdateActionRequest;
    options?: Partial<ApiRequestOptions<void, "actions/updateAction">>;
}

export const updateAction = makeAction((
    { routeParams, request, options }: UpdateActionActionRequest,
) => apiPost<void, "actions/updateAction">(
    "actions/updateAction",
    `actions/${routeParams.actionId}`,
    request,
    options,
), "actions/updateAction");

export interface CompleteActionActionRequest {
    routeParams: {
        actionId: string;
    };
    request: CompleteActionRequest;
    options?: Partial<ApiRequestOptions<void, "actions/completeAction">>;
}

export const completeAction = makeAction((
    { routeParams, request, options }: CompleteActionActionRequest,
) => apiPost<void, "actions/completeAction">(
    "actions/completeAction",
    `actions/${routeParams.actionId}/complete`,
    request,
    options,
), "actions/completeAction");

export interface ListActionRemarksActionRequest {
    routeParams: {
        actionId: string;
    };
    options?: Partial<
        ApiRequestOptions<ActionRemarkListView[], "actions/listActionRemarks">
    >;
}

export const listActionRemarks = makeAction((
    { routeParams, options }: ListActionRemarksActionRequest,
) => apiGet<ActionRemarkListView[], "actions/listActionRemarks">(
    "actions/listActionRemarks",
    `actions/${routeParams.actionId}/remarks`,
    undefined,
    options,
), "actions/listActionRemarks");

export interface CreateActionRemarkActionRequest {
    routeParams: {
        actionId: string;
    };
    request: CreateActionRemarkRequest;
    options?: Partial<ApiRequestOptions<void, "actions/createActionRemark">>;
}

export const createActionRemark = makeAction((
    { routeParams, request, options }: CreateActionRemarkActionRequest,
) => apiPost<void, "actions/createActionRemark">(
    "actions/createActionRemark",
    `actions/${routeParams.actionId}/remarks`,
    request,
    options,
), "actions/createActionRemark");

export interface UpdateActionRemarkActionRequest {
    routeParams: {
        actionId: string;
        remarkId: string;
    };
    request: UpdateActionRemarkRequest;
    options?: Partial<ApiRequestOptions<void, "actions/updateActionRemark">>;
}

export const updateActionRemark = makeAction((
    { routeParams, request, options }: UpdateActionRemarkActionRequest,
) => apiPut<void, "actions/updateActionRemark">(
    "actions/updateActionRemark",
    `actions/${routeParams.actionId}/remarks/${routeParams.remarkId}`,
    request,
    options,
), "actions/updateActionRemark");

export interface DeleteActionRemarkActionRequest {
    routeParams: {
        actionId: string;
        remarkId: string;
    };
    options?: Partial<ApiRequestOptions<void, "actions/deleteActionRemark">>;
}

export const deleteActionRemark = makeAction((
    { routeParams, options }: DeleteActionRemarkActionRequest,
) => apiDelete<void, "actions/deleteActionRemark">(
    "actions/deleteActionRemark",
    `actions/${routeParams.actionId}/remarks/${routeParams.remarkId}`,
    undefined,
    options,
), "actions/deleteActionRemark");
