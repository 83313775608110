import { Button } from "@mui/material";
import { Form, Modal, ModalBuilder } from "@redriver/cinnamon-mui";
import { updateDocumentsVisibility } from "./actions";

const UpdateDocumentVisibility = ({
  as: As = Button,
  documentIds,
  initialData = {},
  onUpdated,
  ...props
}) => (
  <ModalBuilder
    withForm
    initialData={initialData}
    submitAction={updateDocumentsVisibility}
    onSubmitted={onUpdated}
    submitParams={documentIds}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Update Visibility" size="sm">
        <Form {...formProps}>
          <Form.Group columns={2}>
            <Form.Checkbox
              label="Visible to Resource"
              field="visibleToResource"
              fluid
            />
            <Form.Checkbox
              label="Visible to Client"
              field="visibleToClient"
              fluid
            />
          </Form.Group>
        </Form>
      </Modal.Edit>
    )}
  />
);

export default UpdateDocumentVisibility;
