import { ActionMenu } from "@redriver/cinnamon-mui";
import { DownloadDocuments } from "../DownloadDocuments";
import UpdateDocumentVisibility from "./UpdateDocumentVisibility";

const DocumentActions = ({ documents, documentIds, onSubmitted }) => (
  <ActionMenu>
    <DownloadDocuments as={ActionMenu.Item} documentIds={documentIds}>
      {documentIds && documentIds?.length > 1
        ? "Download " + documentIds?.length + " Documents"
        : "Download Document"}
    </DownloadDocuments>
    <UpdateDocumentVisibility
      as={ActionMenu.Item}
      documentIds={documentIds}
      initialData={{
        visibleToResource: documents
          ?.filter((d) => documentIds?.some((i) => d.id === i))
          .every((d) => !!d.visibleOnResourcePortal),
        visibleToClient: documents
          ?.filter((d) => documentIds?.some((i) => d.id === i))
          .every((d) => !!d.visibleOnClientPortal),
      }}
      disabled={
        !documentIds ||
        documentIds?.length == 0 ||
        documents?.some(
          (d) =>
            !d.uploadedByInternalUser && documentIds?.some((i) => d.id === i)
        )
      }
      onUpdated={onSubmitted}
    >
      Update Visibility
    </UpdateDocumentVisibility>
  </ActionMenu>
);

export default DocumentActions;
