import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form, Lookup } from "@redriver/cinnamon-mui";
import { getCommodities } from "./actions";

const mapNodes = (commodities) => {
  return commodities.map((c) => ({
    value: c.id,
    label: c.name,
    ...(c.children?.length > 0 && { children: mapNodes(c.children) }),
  }));
};

const CommoditiesCheckboxTree = ({ lookupAction, ...props }) => (
  <Lookup
    lookupAction={lookupAction ?? getCommodities}
    lookupOptions={{
      cacheExpiry: 60 * 1000,
      transform: (data) => mapNodes(data),
    }}
    render={({ loading, response }) =>
      !loading &&
      response && (
        <Form.CheckboxTree
          {...props}
          nodes={response}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        />
      )
    }
  />
);

export default CommoditiesCheckboxTree;
