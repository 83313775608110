import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Stack, Box, Typography } from "@mui/material";
import { ListBuilder, TileGrid } from "@redriver/cinnamon-mui";
import { LoadMoreButton } from "components/buttons";
import { ContentContainer, HeaderContainer } from "components/containers";
import { ActionStatus } from "constants/enums";
import { PreferenceKeys } from "constants/preferences";
import { ViewAction } from "../ViewAction";
import { getActions } from "./actions";
import FilterActions from "./FilterActions";
import ActionTile from "./ActionTile";

const ListActions = () => {
  const location = useLocation();
  const [selectedAction, setSelectedAction] = useState(
    location.state?.actionId ?? null
  );
  const [tileLastUpdated, setTileLastUpdated] = useState(null);

  return (
    <ListBuilder
      withTiles
      loadAction={getActions}
      onLoaded={(data) => {
        // ensure the selected action is still in the list
        // (unless selected action in routing state)
        setSelectedAction((prev) =>
          location.state?.actionId === prev ||
          data?.results?.some((a) => a.id === prev)
            ? prev
            : null
        );
      }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      persistFilters={PreferenceKeys.ActionFilters}
      initialFilters={{
        includeAssigned: true,
        statusOptions: [
          { value: ActionStatus.New },
          { value: ActionStatus.InProgress },
        ],
        statuses: [ActionStatus.New, ActionStatus.InProgress],
      }}
      renderList={(tileProps, state, events) => (
        <React.Fragment>
          <HeaderContainer variant="shadow">
            <FilterActions
              value={state.filters}
              onChange={events.onChangeFilters}
              onRefresh={events.onRefresh}
            />
          </HeaderContainer>
          <ContentContainer sx={{ flexGrow: 1 }}>
            <Stack direction="row" spacing={2} minHeight="100%">
              <Stack direction="column" spacing={2} flex={1}>
                <TileGrid
                  {...tileProps}
                  dataKey="id"
                  emptyMessage="No actions found"
                  spacing={1}
                  render={(item) => (
                    <ActionTile
                      action={item}
                      active={item.id === selectedAction}
                      onClick={() => setSelectedAction(item.id)}
                      onUpdated={() => {
                        events.onRefresh();
                        setTileLastUpdated(Date.now());
                      }}
                    />
                  )}
                />
                <LoadMoreButton
                  hasMore={state.hasMore}
                  loadingMore={state.loadingMore}
                  onLoadMore={events.onLoadMore}
                  sx={{ alignSelf: "center" }}
                />
              </Stack>
              {tileProps.data?.length > 0 && (
                <Box
                  flex={1}
                  sx={{
                    "> .CinLoadable-root": {
                      minHeight: "100%",
                      display: "flex",
                      "> .content-panel": {
                        width: "100%",
                      },
                    },
                  }}
                >
                  {selectedAction ? (
                    <ViewAction
                      key={tileLastUpdated} // force refresh if action tile updated
                      actionId={selectedAction}
                      onUpdated={events.onRefresh}
                    />
                  ) : (
                    <Typography textAlign="center" mt={2}>
                      Please select an action.
                    </Typography>
                  )}
                </Box>
              )}
            </Stack>
          </ContentContainer>
        </React.Fragment>
      )}
    />
  );
};

export default ListActions;
