import { Form, useFormField } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { FilterDrawer } from "components/containers";
import { InternalUserTypeAhead } from "components/forms";
import { Actions, Targets } from "constants/permissions";
import { actionStatusLookup, actionPrioritiesLookup } from "modules/lookups";

const ActionFilterDrawer = ({ open, onClose, excludeFields }) => {
  const [includeAllActions, _] = useFormField("includeAllActions");

  return (
    <FilterDrawer
      title="Action Filters"
      open={open}
      onClose={onClose}
      excludeFields={excludeFields}
    >
      <PermissionCheck action={Actions.View} target={Targets.OtherUsersActions}>
        <Form.Checkbox
          field="includeAllActions"
          label="All Actions"
          sx={{ mb: 0.5 }}
        />
        <Form.Trigger
          event="onMatch"
          field="includeAllActions"
          target="includeAssigned"
          match={({ fields }) => !!fields.includeAllActions}
          action="clear"
        />
        <Form.Trigger
          event="onMatch"
          field="includeAllActions"
          target="includeCreated"
          match={({ fields }) => !!fields.includeAllActions}
          action="clear"
        />
        <Form.Trigger
          event="onMatch"
          field="includeCreated"
          target="includeAllActions"
          match={({ fields }) => !!fields.includeCreated}
          action="clear"
        />
        <Form.Trigger
          event="onMatch"
          field="includeAssigned"
          target="includeAllActions"
          match={({ fields }) => !!fields.includeAssigned}
          action="clear"
        />
      </PermissionCheck>
      <Form.Checkbox
        field="includeAssigned"
        label="Actions assigned to me"
        sx={{ mb: 0.5 }}
      />
      <Form.Checkbox field="includeCreated" label="Actions I created" />
      <Form.Dropdown
        field="statusOptions"
        valueField="statuses"
        label="Status"
        lookup={actionStatusLookup}
        multiple
        fluid
      />
      <Form.Dropdown
        field="priorityOptions"
        valueField="priorities"
        label="Priority"
        lookup={actionPrioritiesLookup}
        multiple
        fluid
      />
      <InternalUserTypeAhead
        field="users"
        valueField="userIds"
        label="Users"
        disabled={!includeAllActions}
        multiple
        fluid
      />
    </FilterDrawer>
  );
};

export default ActionFilterDrawer;
