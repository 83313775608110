export const DocumentCategoryId = {
  VisitReports: 10,
  FlashReports: 11,
  TimesheetsAndExpenses: 12,
  ResourceInvoice: 20,
  AssociatedDocuments: 22,
};

export const isReportDocumentCategory = (categoryId: number | string) =>
  categoryId == DocumentCategoryId.VisitReports ||
  categoryId == DocumentCategoryId.FlashReports;
