import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 17.588 13.437"
    fill="currentColor"
  >
    <g id="open-book" transform="translate(0 -35.048)">
      <g id="Group_7956" data-name="Group 7956" transform="translate(0 35.048)">
        <g id="Group_7955" data-name="Group 7955" transform="translate(0 0)">
          <path
            id="Path_5888"
            data-name="Path 5888"
            d="M35.461,35.049h0a.62.62,0,0,0-.441.183.627.627,0,0,0-.185.447v9.438a.632.632,0,0,0,.63.629,7.786,7.786,0,0,1,5.613,2.081V37.949a.6.6,0,0,0-.087-.319C39.6,35.392,36.929,35.052,35.461,35.049Z"
            transform="translate(-32.77 -35.049)"
          />
          <path
            id="Path_5889"
            data-name="Path 5889"
            d="M162.994,45.115V35.677a.627.627,0,0,0-.185-.447.62.62,0,0,0-.441-.183h0c-1.468,0-4.139.344-5.529,2.582a.6.6,0,0,0-.086.319v9.877a7.786,7.786,0,0,1,5.613-2.081A.631.631,0,0,0,162.994,45.115Z"
            transform="translate(-147.468 -35.048)"
          />
          <path
            id="Path_5890"
            data-name="Path 5890"
            d="M178.286,71.8h-.456v7.891a1.61,1.61,0,0,1-1.6,1.606,6.981,6.981,0,0,0-4.743,1.619,15.151,15.151,0,0,1,6.663.129.629.629,0,0,0,.77-.613v-10A.63.63,0,0,0,178.286,71.8Z"
            transform="translate(-161.327 -69.624)"
          />
          <path
            id="Path_5891"
            data-name="Path 5891"
            d="M1.086,79.692V71.8H.629A.63.63,0,0,0,0,72.43v10a.629.629,0,0,0,.77.613,15.15,15.15,0,0,1,6.663-.129A6.981,6.981,0,0,0,2.691,81.3,1.61,1.61,0,0,1,1.086,79.692Z"
            transform="translate(0 -69.624)"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Disciplines"
);
