import { ModalBuilder, Modal } from "@redriver/cinnamon-mui";
import { AddButton } from "components/buttons";
import { ActionStatus } from "constants/enums";
import { ActionForm } from "../ActionForm";
import { addAction } from "./actions";

const AddAction = ({ as: As = AddButton, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    initialData={{
      statusOption: { value: ActionStatus.New },
    }}
    submitAction={addAction}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Action">
        <ActionForm formProps={formProps} />
      </Modal.Add>
    )}
  />
);

export default AddAction;
