import { useState } from "react";
import { Typography, Badge } from "@mui/material";
import { FormBuilder, Form, ActionBar } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { FilterButton } from "components/buttons";
import { SearchInput } from "components/forms";
import { Actions, Targets } from "constants/permissions";
import { useFilterTotals } from "modules/helpers";
import AddAction from "./AddAction";
import ActionFilterDrawer from "./ActionFilterDrawer";

const FilterActions = ({ value, onChange, onRefresh }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const excludeFields = ["search"];
  const totalFilters = useFilterTotals(value, excludeFields);

  return (
    <FormBuilder
      value={value}
      onChange={onChange}
      renderForm={(formProps) => (
        <Form {...formProps}>
          <ActionBar>
            <ActionBar.Item>
              <Typography variant="h5">Actions</Typography>
            </ActionBar.Item>
            <ActionBar.Item>
              <SearchInput field="search" />
            </ActionBar.Item>
            <ActionBar.Item fluid align="right">
              <Badge badgeContent={totalFilters} color="primary">
                <FilterButton onClick={() => setDrawerOpen(true)}>
                  Filters
                </FilterButton>
              </Badge>
            </ActionBar.Item>
            <ActionBar.Item>
              <PermissionCheck action={Actions.Create} target={Targets.Action}>
                <AddAction onAdded={onRefresh}>Add Action</AddAction>
              </PermissionCheck>
            </ActionBar.Item>
          </ActionBar>
          <ActionFilterDrawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
        </Form>
      )}
    />
  );
};

export default FilterActions;
