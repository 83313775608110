import { Paper, Typography, alpha } from "@mui/material";

const ChartValueTooltip = ({
  active,
  payload,
  nameKey = "name",
  valueKey = "value",
  formatName = (name) => name,
  formatValue = (value) => value,
}) => {
  if (!active || !payload?.length) return null;
  return (
    <Paper
      sx={({ palette }) => ({
        px: 1,
        py: 0.5,
        // match style to MUI Tooltip
        color: palette.common.white,
        backgroundColor: alpha(palette.grey[700], 0.92),
      })}
    >
      {payload.map((p) => (
        <Typography key={p.dataKey} fontSize="0.8rem">
          {formatName(p.payload[nameKey], p)}: {formatValue(p[valueKey], p)}
        </Typography>
      ))}
    </Paper>
  );
};

export default ChartValueTooltip;
