import * as documents from "mortar/endpoints/documents";
import { UpdateDocumentsVisibilityRequest } from "mortar/EnergyEngineering/Endpoints/Documents";

export const updateDocumentsVisibility = (
  data: UpdateDocumentsVisibilityRequest,
  documentIds: string[]
) =>
  documents.updateDocumentsVisibility({
    request: {
      ...data,
      documentIds,
    },
  });
