import { Button } from "@mui/material";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon-mui";
import { completeAction } from "./actions";

const CompleteAction = ({
  as: As = Button,
  actionId,
  onCompleted,
  ...props
}) => (
  <ModalBuilder
    withForm
    submitAction={completeAction}
    submitParams={actionId}
    onSubmitted={onCompleted}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Complete Action">
        <Form {...formProps}>
          <Form.TextArea
            field="remarks"
            label="Comment (Optional)"
            rows={4}
            fluid
          />
        </Form>
      </Modal.Edit>
    )}
  />
);

export default CompleteAction;
