import { Box } from "@mui/material";
import { ListBuilder, TileGrid, DataList } from "@redriver/cinnamon-mui";
import { getRemarks } from "./actions";
import RemarkRow from "./RemarkRow";

const ListRemarks = ({ actionId, remarkLastAdded }) => (
  <ListBuilder
    withTiles
    loadAction={getRemarks}
    loadParams={{ actionId, remarkLastAdded }}
    renderList={(tileProps, state, events) => (
      <Box>
        <DataList>
          <DataList.Item
            title="Remarks"
            fallback={tileProps.data?.length > 0 ? "" : undefined}
          />
        </DataList>
        <TileGrid
          {...tileProps}
          dataKey="id"
          emptyMessage=""
          spacing={1}
          render={(item) => (
            <RemarkRow
              actionId={actionId}
              remark={item}
              onUpdated={events.onRefresh}
            />
          )}
        />
      </Box>
    )}
  />
);

export default ListRemarks;
