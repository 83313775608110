import { AppPageContent } from "components/pages";
import { ListActions } from "features/Actions";

const ActionsPage = () => (
  <AppPageContent>
    <ListActions />
  </AppPageContent>
);

export default ActionsPage;
