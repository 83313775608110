import { Form } from "@redriver/cinnamon-mui";
import {
  InternalUserTypeAhead,
  EnquiryTypeAhead,
  JobTypeAhead,
  ClientTypeAhead,
  ContractTypeAhead,
  ResourceTypeAhead,
  EnquiryScopeTypeAhead,
  JobScopeTypeAhead,
} from "components/forms";
import { ActionLinkType } from "constants/enums";
import {
  actionStatusLookup,
  actionPrioritiesLookup,
  actionLinkTypesLookup,
} from "modules/lookups";
import { ContractStatus } from "mortar/EnergyEngineering/Enums";

const getLinkedEntityTypeAhead = (linkedEntityType) => {
  switch (linkedEntityType) {
    case ActionLinkType.Job:
      return JobTypeAhead;
    case ActionLinkType.Enquiry:
      return EnquiryTypeAhead;
    case ActionLinkType.Client:
      return ClientTypeAhead;
    case ActionLinkType.Contract:
      return ContractTypeAhead;
    case ActionLinkType.Resource:
      return ResourceTypeAhead;
    case ActionLinkType.EnquiryScope:
      return EnquiryScopeTypeAhead;
    case ActionLinkType.JobScope:
      return JobScopeTypeAhead;
    default:
      return Form.TypeAhead; // unknown type, render empty type ahead
  }
};

const ActionForm = ({ formProps, isOwner = true, fromJob = false }) => {
  const LinkedEntityTypeAhead = getLinkedEntityTypeAhead(
    formProps.value.linkedEntityType
  );

  return (
    <Form {...formProps}>
      <Form.Input
        field="title"
        label="Title"
        fluid
        required
        disabled={!isOwner}
      />
      <InternalUserTypeAhead
        field="assignedUsers"
        valueField="assignedUserIds"
        label="Assigned Users"
        multiple
        fluid
        required
      />
      <Form.TextArea
        field="description"
        label="Description"
        placeholder="Type description here..."
        rows={4}
        fluid
        required
        disabled={!isOwner}
      />
      <Form.Group columns={2}>
        <Form.Dropdown
          field="statusOption"
          valueField="status"
          label="Status"
          lookup={actionStatusLookup}
          fluid
          required
        />
        <Form.Dropdown
          field="priorityOption"
          valueField="priority"
          label="Priority"
          lookup={actionPrioritiesLookup}
          fluid
          required
          disabled={!isOwner}
        />
      </Form.Group>
      <Form.Group columns={2}>
        <Form.Dropdown
          field="linkedEntityTypeOption"
          valueField="linkedEntityType"
          label="Link to Type"
          lookup={actionLinkTypesLookup}
          disabled={!isOwner || !!fromJob}
          required
          fluid
        />
        <Form.Trigger
          field="linkedEntityTypeOption"
          target="linkedEntity"
          action="clear"
        />
        {LinkedEntityTypeAhead && (
          <LinkedEntityTypeAhead
            field="linkedEntity"
            valueField="linkedEntityId"
            label="Linked Entity"
            status={
              formProps.value.linkedEntityType === ActionLinkType.Contract && [
                ContractStatus.Draft,
                ContractStatus.Inactive,
              ]
            }
            disabled={
              !formProps.value.linkedEntityType || !isOwner || !!fromJob
            }
            required
            fluid
          />
        )}
      </Form.Group>
    </Form>
  );
};

export default ActionForm;
