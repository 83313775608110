import React from "react";
import { Button } from "@mui/material";
import { TriggerBuilder } from "@redriver/cinnamon-mui";
import { saveFile } from "modules/helpers";
import { downloadDocumentLatestVersion } from "./actions";

const DownloadDocumentLatestVersion = React.forwardRef(
  ({ as: As = Button, documentId, ...props }, ref) => (
    <TriggerBuilder
      submitAction={downloadDocumentLatestVersion}
      submitParams={{ documentId }}
      onSubmitted={(data) => saveFile(data, data.name)}
      renderTrigger={(download) => (
        <As {...props} onClick={download} ref={ref} />
      )}
    />
  )
);

export default DownloadDocumentLatestVersion;
