import { Button } from "@mui/material";
import { ModalBuilder, Modal } from "@redriver/cinnamon-mui";
import { deleteRemark } from "./actions";

const DeleteRemark = ({
  as: As = Button,
  actionId,
  remarkId,
  onDeleted,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteRemark}
    submitParams={{ actionId, remarkId }}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps) => (
      <Modal.Delete {...modalProps} header="Delete Remark">
        Are you sure you wish to delete this remark?
      </Modal.Delete>
    )}
  />
);

export default DeleteRemark;
