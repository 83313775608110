import { TreeItem } from "@mui/lab";
import { styled } from "@mui/material";

const StyledTreeItem = styled(TreeItem)({
  "> .MuiTreeItem-content": {
    "> .MuiTreeItem-label": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "0.25em",
      paddingBottom: "0.25em",
      "> .actions": {
        cursor: "default",
        paddingLeft: "1em",
        opacity: 0,
      },
    },
    "&:hover > .MuiTreeItem-label > .actions": {
      opacity: 1,
    },
  },
});

export default StyledTreeItem;
