import { useTheme } from "@mui/material";
import { Format } from "@redriver/cinnamon-mui";
import { ChartValueTooltip, LegendItem, LegendStack } from "components/charts";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BudgetSalesMarginChart = ({ budget, width, height }) => {
  const { palette } = useTheme();
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={[
          {
            name: "Charge to Client",
            Completed: budget.clientChargeCompleted,
            Booked: budget.clientChargeBooked,
          },
          {
            name: "Resource Cost",
            Completed: budget.resourceCostCompleted,
            Booked: budget.resourceCostBooked,
          },
        ]}
        barCategoryGap="20%"
      >
        <XAxis dataKey="name" style={{ fontSize: "0.9em", fontWeight: 500 }} />
        <Tooltip
          content={
            <ChartValueTooltip
              formatName={(_, payload) => payload.name}
              formatValue={(value) => (
                <Format.Number value={value} decimalPlaces={0} />
              )}
            />
          }
        />
        <Legend
          verticalAlign="top"
          height={60}
          content={
            <LegendStack
              direction="row"
              spacing={2}
              alignItems="flex-start"
              justifyContent="center"
            >
              <LegendItem value="Completed" fill={palette.success.light} />
              <LegendItem value="Booked" fill={palette.secondary.dark} />
            </LegendStack>
          }
        />
        <Bar dataKey="Completed" stackId="hours" fill={palette.success.light} />
        <Bar dataKey="Booked" stackId="hours" fill={palette.secondary.dark} />
        <YAxis />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BudgetSalesMarginChart;
