import { LoadingButton } from "@mui/lab";
import { TriggerBuilder } from "@redriver/cinnamon-mui";
import { saveFile } from "modules/helpers";
import { downloadDocuments } from "./actions";

const DownloadDocuments = ({
  as: As = LoadingButton,
  documentIds,
  ...props
}) => (
  <TriggerBuilder
    submitAction={downloadDocuments}
    submitParams={documentIds}
    onSubmitted={(data) => saveFile(data, data.name)}
    renderTrigger={(fireTrigger, state) => (
      <As
        {...props}
        disabled={state.submitting || !documentIds || documentIds?.length == 0}
        loading={state.submitting && state.slowSubmitting}
        onClick={fireTrigger}
      />
    )}
  />
);

export default DownloadDocuments;
