import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

class InfoMarker extends React.Component {
  state = {
    showInfoWindow: false,
    windowOpen: false,
  };

  handleMouseOver = () => {
    this.setState({ showInfoWindow: true });
  };

  handleMouseExit = () => {
    this.setState({ showInfoWindow: false });
  };

  handleMouseDown = () => {
    this.setState({ windowOpen: true });
  };

  onMarkerClose = () => {
    this.setState({ windowOpen: false });
  };

  render() {
    const { showInfoWindow, windowOpen } = this.state;
    const { children, ...otherProps } = this.props;

    return (
      <Marker
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseExit}
        onMouseDown={this.handleMouseDown}
        {...otherProps}
      >
        {(showInfoWindow || windowOpen) && (
          <InfoWindow onCloseClick={this.onMarkerClose}>
            <React.Fragment>{children}</React.Fragment>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default InfoMarker;
