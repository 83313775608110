import * as documents from "mortar/endpoints/documents";

export const uploadDocumentVersion = (
  { file }: { file: any },
  documentId: string
) =>
  documents.uploadDocumentVersion({
    routeParams: {
      documentId,
    },
    formParams: {
      file,
    },
  });
