import * as documents from "mortar/endpoints/documents";

export const getDocumentHistory = (documentId: string) =>
  documents.getDocumentHistory({
    routeParams: {
      documentId,
    },
    queryParams: {
      maxResults: 100,
    },
  });
