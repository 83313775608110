// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import {
    CreateCommodityRequest,
    ReorderCommodityRequest,
    UpdateCommodityRequest,
} from "mortar/EnergyEngineering/Endpoints/Admin/Commodities";
import { CommodityView } from "mortar/EnergyEngineering/Logic/Commodities/Views";
import { makeAction, makeFormData } from "../lib";

export interface ListCommoditiesActionRequest {
    queryParams: {
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<CommodityView[], "commodities/listCommodities">
    >;
}

export const listCommodities = makeAction((
    { queryParams, options }: ListCommoditiesActionRequest,
) => apiGet<CommodityView[], "commodities/listCommodities">(
    "commodities/listCommodities",
    `commodities`,
    queryParams,
    options,
), "commodities/listCommodities");

export interface CreateCommodityActionRequest {
    request: CreateCommodityRequest;
    options?: Partial<ApiRequestOptions<void, "commodities/createCommodity">>;
}

export const createCommodity = makeAction((
    { request, options }: CreateCommodityActionRequest,
) => apiPost<void, "commodities/createCommodity">(
    "commodities/createCommodity",
    `commodities`,
    request,
    options,
), "commodities/createCommodity");

export interface UpdateCommodityActionRequest {
    routeParams: {
        commodityId: string;
    };
    request: UpdateCommodityRequest;
    options?: Partial<ApiRequestOptions<void, "commodities/updateCommodity">>;
}

export const updateCommodity = makeAction((
    { routeParams, request, options }: UpdateCommodityActionRequest,
) => apiPut<void, "commodities/updateCommodity">(
    "commodities/updateCommodity",
    `commodities/${routeParams.commodityId}`,
    request,
    options,
), "commodities/updateCommodity");

export interface DeleteCommodityActionRequest {
    routeParams: {
        commodityId: string;
    };
    options?: Partial<ApiRequestOptions<void, "commodities/deleteCommodity">>;
}

export const deleteCommodity = makeAction((
    { routeParams, options }: DeleteCommodityActionRequest,
) => apiDelete<void, "commodities/deleteCommodity">(
    "commodities/deleteCommodity",
    `commodities/${routeParams.commodityId}`,
    undefined,
    options,
), "commodities/deleteCommodity");

export interface ReorderCommodityActionRequest {
    routeParams: {
        commodityId: string;
    };
    request: ReorderCommodityRequest;
    options?: Partial<ApiRequestOptions<void, "commodities/reorderCommodity">>;
}

export const reorderCommodity = makeAction((
    { routeParams, request, options }: ReorderCommodityActionRequest,
) => apiPut<void, "commodities/reorderCommodity">(
    "commodities/reorderCommodity",
    `commodities/${routeParams.commodityId}/reorder`,
    request,
    options,
), "commodities/reorderCommodity");
