import classNames from "classnames";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { ModalBuilder, Modal, Table, Format } from "@redriver/cinnamon-mui";
import { formatFileSize } from "modules/helpers";
import { DownloadDocumentVersion } from "../DownloadDocuments";
import { RevertDocumentVersion } from "../RevertDocumentVersion";
import { getDocumentHistory } from "./actions";
import DownloadIcon from "@mui/icons-material/Download";
import RestoreIcon from "@mui/icons-material/Restore";

const ViewDocumentHistory = ({
  as: As = Button,
  onUpdated,
  documentId,
  canEdit = false,
  ...props
}) => (
  <ModalBuilder
    initialData={[]}
    loadAction={getDocumentHistory}
    loadParams={documentId}
    loadTransform={(data) =>
      data.map((v) => ({
        ...v,
        className: classNames({ latest: v.isLatestVersion }),
      })) ?? []
    }
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, versions, state, events) => (
      <Modal.Information
        {...modalProps}
        size="lg"
        header="Document History"
        submitLabel="Close"
      >
        <Table
          data={versions}
          dataKey="id"
          sx={{
            "tr.latest > td": {
              fontWeight: "bold",
            },
          }}
        >
          <Table.Column title="Filename" field="fileName" />
          <Table.Column
            title="Size"
            render={(item) => formatFileSize(item.fileSize)}
          />
          <Table.Column
            title="Uploaded"
            render={(item) => <Format.DateTime value={item.createdUtc} />}
          />
          <Table.Column
            title="Uploaded By"
            render={(item) => item.createdBy?.label}
          />
          <Table.Column
            title="Reverted"
            render={(item) =>
              item.revertedUtc ? (
                <Tooltip title={`Reverted by ${item.revertedBy.label}`}>
                  <span>
                    <Format.DateTime value={item.revertedUtc} />
                  </span>
                </Tooltip>
              ) : null
            }
          />
          <Table.Column
            render={(version) => (
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <Tooltip title="Download">
                  <DownloadDocumentVersion
                    as={IconButton}
                    color="primary"
                    documentId={documentId}
                    versionId={version.id}
                  >
                    <DownloadIcon />
                  </DownloadDocumentVersion>
                </Tooltip>
                {canEdit && (
                  <Tooltip title="Revert to this version">
                    <span>
                      <RevertDocumentVersion
                        as={IconButton}
                        color="warning"
                        disabled={version.isLatestVersion}
                        documentId={documentId}
                        version={version}
                        onReverted={events.onReset}
                      >
                        <RestoreIcon />
                      </RevertDocumentVersion>
                    </span>
                  </Tooltip>
                )}
              </Stack>
            )}
          />
        </Table>
      </Modal.Information>
    )}
  />
);

export default ViewDocumentHistory;
