import { ListBuilderState } from "@redriver/cinnamon-mui";
import * as actions from "mortar/endpoints/actions";
import { UpdateActionRequest } from "mortar/EnergyEngineering/Endpoints/Actions";
import {
  CreateActionRemarkRequest,
  UpdateActionRemarkRequest,
} from "mortar/EnergyEngineering/Endpoints/Actions/Remarks";

export const getAction = (actionId: string) =>
  actions.getAction({
    routeParams: {
      actionId,
    },
  });

export const updateAction = (request: UpdateActionRequest, actionId: string) =>
  actions.updateAction({
    routeParams: {
      actionId,
    },
    request,
  });

export const getRemarks = (
  _: ListBuilderState,
  { actionId }: { actionId: string }
) =>
  actions.listActionRemarks({
    routeParams: {
      actionId,
    },
  });

export const addRemark = (
  request: CreateActionRemarkRequest,
  actionId: string
) =>
  actions.createActionRemark({
    routeParams: {
      actionId,
    },
    request,
  });

export const updateRemark = (
  request: UpdateActionRemarkRequest,
  {
    actionId,
    remarkId,
  }: {
    actionId: string;
    remarkId: string;
  }
) =>
  actions.updateActionRemark({
    routeParams: {
      actionId,
      remarkId,
    },
    request,
  });

export const deleteRemark = ({
  actionId,
  remarkId,
}: {
  actionId: string;
  remarkId: string;
}) =>
  actions.deleteActionRemark({
    routeParams: {
      actionId,
      remarkId,
    },
  });
