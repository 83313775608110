// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import {
    MultipleDocumentDownloadRequest,
    UpdateDocumentsVisibilityRequest,
} from "mortar/EnergyEngineering/Endpoints/Documents";
import { DocumentVersionView } from "mortar/EnergyEngineering/Logic/Documents/Views";
import { makeAction, makeFormData } from "../lib";

export interface UpdateDocumentsVisibilityActionRequest {
    request: UpdateDocumentsVisibilityRequest;
    options?: Partial<
        ApiRequestOptions<void, "documents/updateDocumentsVisibility">
    >;
}

export const updateDocumentsVisibility = makeAction((
    { request, options }: UpdateDocumentsVisibilityActionRequest,
) => apiPut<void, "documents/updateDocumentsVisibility">(
    "documents/updateDocumentsVisibility",
    `documents/visibility`,
    request,
    options,
), "documents/updateDocumentsVisibility");

export interface DownloadMultipleDocumentsActionRequest {
    request: MultipleDocumentDownloadRequest;
    options?: Partial<
        ApiRequestOptions<File, "documents/downloadMultipleDocuments">
    >;
}

export const downloadMultipleDocuments = makeAction((
    { request, options }: DownloadMultipleDocumentsActionRequest,
) => apiPost<File, "documents/downloadMultipleDocuments">(
    "documents/downloadMultipleDocuments",
    `documents/zip`,
    request,
    options,
), "documents/downloadMultipleDocuments");

export interface DownloadDocumentActionRequest {
    routeParams: {
        documentId: string;
    };
    options?: Partial<ApiRequestOptions<File, "documents/downloadDocument">>;
}

export const downloadDocument = makeAction((
    { routeParams, options }: DownloadDocumentActionRequest,
) => apiGet<File, "documents/downloadDocument">(
    "documents/downloadDocument",
    `documents/${routeParams.documentId}`,
    undefined,
    options,
), "documents/downloadDocument");

export interface GetDocumentHistoryActionRequest {
    routeParams: {
        documentId: string;
    };
    queryParams: {
        maxResults: number;
    };
    options?: Partial<
        ApiRequestOptions<DocumentVersionView[], "documents/getDocumentHistory">
    >;
}

export const getDocumentHistory = makeAction((
    { routeParams, queryParams, options }: GetDocumentHistoryActionRequest,
) => apiGet<DocumentVersionView[], "documents/getDocumentHistory">(
    "documents/getDocumentHistory",
    `documents/${routeParams.documentId}/history`,
    queryParams,
    options,
), "documents/getDocumentHistory");

export interface UploadDocumentVersionActionFormData {
    file: File;
}

export interface UploadDocumentVersionActionRequest {
    routeParams: {
        documentId: string;
    };
    formParams: UploadDocumentVersionActionFormData;
    formTransform?: (request: UploadDocumentVersionActionFormData) => FormData;
    options?: Partial<
        ApiRequestOptions<void, "documents/uploadDocumentVersion">
    >;
}

export const uploadDocumentVersion = makeAction((
    { routeParams, formParams, formTransform, options }:
        UploadDocumentVersionActionRequest,
) => apiPost<void, "documents/uploadDocumentVersion">(
    "documents/uploadDocumentVersion",
    `documents/${routeParams.documentId}/versions`,
    (formTransform || makeFormData)(formParams, {
        file: "Append",
    }),
    { contentType: null, ...options },
), "documents/uploadDocumentVersion");

export interface DownloadDocumentVersionActionRequest {
    routeParams: {
        documentId: string;
        versionId: string;
    };
    options?: Partial<
        ApiRequestOptions<File, "documents/downloadDocumentVersion">
    >;
}

export const downloadDocumentVersion = makeAction((
    { routeParams, options }: DownloadDocumentVersionActionRequest,
) => apiGet<File, "documents/downloadDocumentVersion">(
    "documents/downloadDocumentVersion",
    `documents/${routeParams.documentId}/versions/${routeParams.versionId}`,
    undefined,
    options,
), "documents/downloadDocumentVersion");

export interface RevertDocumentVersionActionRequest {
    routeParams: {
        documentId: string;
        versionId: string;
    };
    options?: Partial<
        ApiRequestOptions<void, "documents/revertDocumentVersion">
    >;
}

export const revertDocumentVersion = makeAction((
    { routeParams, options }: RevertDocumentVersionActionRequest,
) => apiPost<void, "documents/revertDocumentVersion">(
    "documents/revertDocumentVersion",
    `documents/${routeParams.documentId}/versions/${routeParams.versionId}/revert`,
    undefined,
    options,
), "documents/revertDocumentVersion");
