import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    id="nut"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 14.203 14.203"
    fill="currentColor"
  >
    <path
      id="Path_5886"
      data-name="Path 5886"
      d="M222.545,0h-4.1L216.4,3.551l2.05,3.551h4.1l2.05-3.551ZM220.5,5.326a1.775,1.775,0,1,1,1.775-1.775A1.775,1.775,0,0,1,220.5,5.326Z"
      transform="translate(-210.392)"
    />
    <path
      id="Path_5887"
      data-name="Path 5887"
      d="M2.05,256,0,259.549,2.05,263.1h4.1l2.05-3.551L6.15,256H2.05Zm2.05,5.326a1.775,1.775,0,1,1,1.775-1.775A1.775,1.775,0,0,1,4.1,261.324Z"
      transform="translate(0 -248.897)"
    />
  </svg>,
  "Commodities"
);
