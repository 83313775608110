import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 25 25"
    fill="currentColor"
  >
    <path
      id="chat"
      d="M17.508,0h-10a7.508,7.508,0,0,0-7.5,7.5V13.33a7.509,7.509,0,0,0,7.083,7.487v3.476A.709.709,0,0,0,7.8,25a.7.7,0,0,0,.5-.208l.782-.781a10.8,10.8,0,0,1,7.685-3.183h.742a7.508,7.508,0,0,0,7.5-7.5V7.5A7.508,7.508,0,0,0,17.508,0ZM7.091,12.08a1.666,1.666,0,1,1,1.667-1.666A1.668,1.668,0,0,1,7.091,12.08Zm5.417,0a1.666,1.666,0,1,1,1.667-1.666A1.668,1.668,0,0,1,12.508,12.08Zm5.417,0a1.666,1.666,0,1,1,1.667-1.666A1.668,1.668,0,0,1,17.925,12.08Z"
      transform="translate(-0.008)"
    />
  </svg>,
  "Comment"
);
