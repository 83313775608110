import * as documents from "mortar/endpoints/documents";

export const downloadDocuments = (documentIds: string[]) => {
  if (documentIds.length == 1) {
    const documentId = documentIds[0];
    return documents.downloadDocument({
      routeParams: {
        documentId,
      },
      options: {
        preventResponseHandling: true,
      },
    });
  }
  return documents.downloadMultipleDocuments({
    request: {
      documentIds,
    },
    options: {
      preventResponseHandling: true,
    },
  });
};

export const downloadDocumentVersion = ({
  documentId,
  versionId,
}: {
  documentId: string;
  versionId: string;
}) =>
  documents.downloadDocumentVersion({
    routeParams: {
      documentId,
      versionId,
    },
    options: {
      preventResponseHandling: true,
    },
  });

export const downloadDocumentLatestVersion = ({
  documentId,
}: {
  documentId: string;
}) =>
  documents.downloadDocument({
    routeParams: {
      documentId,
    },
    options: {
      preventResponseHandling: true,
    },
  });
