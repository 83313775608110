export const PreferenceKeys = {
  EnquiryFilters: "enquiryFilters",
  EnquiryView: "enquiryView",
  ProjectFilters: "projectFilters",
  JobFilters: "jobFilters",
  ResourceSearchFilters: "resourceSearchFilters",
  ActionFilters: "ActionFilters",
  ClientFilters: "ClientFilters",
  ContractFilters: "ContractFilters",
  ResourceFilters: "ResourceFilters",
  ReadyToInvoiceFilters: "ReadyToInvoiceFilters",
  DraftInvoicesFilters: "DraftInvoicesFilters",
};
