import React from "react";
import { Button } from "@mui/material";
import { TriggerBuilder } from "@redriver/cinnamon-mui";
import { saveFile } from "modules/helpers";
import { downloadDocumentVersion } from "./actions";

const DownloadDocumentVersion = React.forwardRef(
  ({ as: As = Button, documentId, versionId, ...props }, ref) => (
    <TriggerBuilder
      submitAction={downloadDocumentVersion}
      submitParams={{ documentId, versionId }}
      onSubmitted={(data) => saveFile(data, data.name)}
      renderTrigger={(download) => (
        <As {...props} onClick={download} ref={ref} />
      )}
    />
  )
);

export default DownloadDocumentVersion;
