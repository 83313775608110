import * as documents from "mortar/endpoints/documents";

export const revertDocumentVersion = ({
  documentId,
  versionId,
}: {
  documentId: string;
  versionId: string;
}) =>
  documents.revertDocumentVersion({
    routeParams: {
      documentId,
      versionId,
    },
  });
