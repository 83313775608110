import { Button } from "@mui/material";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon-mui";
import { updateRemark } from "./actions";

const EditRemark = ({
  as: As = Button,
  actionId,
  remark,
  onUpdated,
  ...props
}) => (
  <ModalBuilder
    withForm
    initialData={{
      remarks: remark.remarks,
    }}
    submitAction={updateRemark}
    submitParams={{ actionId, remarkId: remark.id }}
    onSubmitted={onUpdated}
    renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Remark">
        <Form {...formProps}>
          <Form.TextArea
            field="remarks"
            label="Remarks"
            rows={4}
            fluid
            required
          />
        </Form>
      </Modal.Edit>
    )}
  />
);

export default EditRemark;
