import { Typography } from "@mui/material";
import { StyledTreeItem } from "components/trees";

const BasicCommodityNode = ({ commodity }) => {
  return (
    <StyledTreeItem
      sx={{
        "&:hover > .MuiTreeItem-content": {
          backgroundColor: "initial",
        },
        ".Mui-focused": {
          backgroundColor: "initial",
        },
      }}
      nodeId={commodity.id}
      label={
        <Typography mr="auto">
          {commodity.name}
          {commodity.children?.length > 0 && ` (${commodity.children.length})`}
        </Typography>
      }
    >
      {commodity.children?.map((c) => (
        <BasicCommodityNode key={c.id} commodity={c} />
      ))}
    </StyledTreeItem>
  );
};

export default BasicCommodityNode;
