import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 19.05 18.945"
  >
    <g
      id="Group_8025"
      data-name="Group 8025"
      transform="translate(-1773 -1019.055)"
    >
      <path
        id="Path_5940"
        data-name="Path 5940"
        d="M14.287,9.473a4.732,4.732,0,0,0-2.4.655l-.793-.315a.591.591,0,0,1-.376-.551V8.8l.037-.007A2.375,2.375,0,0,0,12.5,6.512V4.144a4.167,4.167,0,0,0-8.334,0V6.512A2.381,2.381,0,0,0,5.953,8.8v.459a.6.6,0,0,1-.372.551l-3.71,1.473A2.948,2.948,0,0,0,0,14.035v1.95a1.191,1.191,0,0,0,1.191,1.184h9.384a4.75,4.75,0,1,0,3.713-7.7Zm0,8.288a3.552,3.552,0,1,1,3.572-3.552A3.568,3.568,0,0,1,14.287,17.761Z"
        transform="translate(1773 1019.055)"
        fill="#207d9a"
      />
      <path
        id="Path_5938"
        data-name="Path 5938"
        d="M324.864,337.114l-2.5,2.5a.622.622,0,0,1-.883,0l-1.25-1.25a.624.624,0,0,1,.883-.883l.809.809,2.059-2.059a.624.624,0,0,1,.883.883Z"
        transform="translate(1464.502 695.28)"
        fill="#207d9a"
      />
    </g>
  </svg>,
  "UserWithTick"
);
