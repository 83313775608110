import React from "react";
import { Typography, IconButton, Stack, Box } from "@mui/material";
import { Format } from "@redriver/cinnamon-mui";
import EditRemark from "./EditRemark";
import DeleteRemark from "./DeleteRemark";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "assets/icons/Delete";

const RemarkRow = ({ actionId, remark, onUpdated }) => {
  const { createdBy, createdUtc, modifiedUtc, remarks } = remark;
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={1}
      bgcolor="background.default"
      px={1}
      py={0.5}
      sx={{
        "> .MuiIconButton-root": { opacity: 0 },
        "&:hover > .MuiIconButton-root": { opacity: 1 },
      }}
    >
      <Box flexGrow={1}>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption">{createdBy?.label}</Typography>
          <Typography variant="caption">
            <Format.DateTime value={createdUtc} />
          </Typography>
          {modifiedUtc && (
            <Typography variant="caption" pl={3}>
              Updated <Format.DateTime value={modifiedUtc} />
            </Typography>
          )}
        </Stack>
        <Typography variant="body1" fontWeight={500}>
          {remarks}
        </Typography>
      </Box>
      {remark.isOwner && (
        <React.Fragment>
          <EditRemark
            as={IconButton}
            color="primary"
            actionId={actionId}
            remark={remark}
            onUpdated={onUpdated}
          >
            <EditIcon />
          </EditRemark>
          <DeleteRemark
            as={IconButton}
            color="primary"
            actionId={actionId}
            remarkId={remark.id}
            onDeleted={onUpdated}
          >
            <DeleteIcon />
          </DeleteRemark>
        </React.Fragment>
      )}
    </Stack>
  );
};

export default RemarkRow;
