import {
  GridColumnMenuFilter,
  GridColumnMenuSort,
} from "@progress/kendo-react-grid";
const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
      <GridColumnMenuSort {...props} />
    </div>
  );
};

export default ColumnMenu;
