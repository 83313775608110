import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const defaultContainerStyle = {
  width: "100%",
  height: "600px",
};

const apiKey = process.env.GOOGLE_MAPS_APIKEY;

function SiteMap({
  children,
  bounds,
  containerStyle = defaultContainerStyle,
  ...props
}) {
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map && bounds) {
      const b = new window.google.maps.LatLngBounds();
      bounds
        .filter((l) => {
          return l.lat != null && l.lng != null;
        })
        .forEach((bound) => {
          b.extend(bound);
        });

      map.fitBounds(b);
    }
  }, [map, bounds]);
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={10}
        onLoad={onLoad}
        {...props}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  );
}

SiteMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bounds: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ),
};

export default React.memo(SiteMap);
