import { useTheme } from "@mui/material";
import { Format } from "@redriver/cinnamon-mui";
import { ChartValueTooltip, LegendItem, LegendStack } from "components/charts";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BudgetHoursChart = ({ budget, width, height }) => {
  const { palette } = useTheme();
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={[
          {
            name: "Budget Hours",
            Completed: budget.completedHours,
            Booked: budget.bookedHours,
            Remaining: budget.remainingHours,
          },
        ]}
        barCategoryGap="30%"
      >
        <XAxis dataKey="name" style={{ fontSize: "0.9em", fontWeight: 500 }} />
        <YAxis />
        <Tooltip
          content={
            <ChartValueTooltip
              formatName={(_, payload) => payload.name}
              formatValue={(value) => (
                <Format.Number
                  value={value}
                  suffix={` ${value === 1 ? "hour" : "hours"}`}
                />
              )}
            />
          }
        />
        <Legend
          verticalAlign="top"
          height={60}
          content={
            <LegendStack
              direction="row"
              spacing={2}
              alignItems="flex-start"
              justifyContent="center"
            >
              <LegendItem value="Completed" fill={palette.success.light} />
              <LegendItem value="Booked" fill={palette.secondary.dark} />
              <LegendItem value="Remaining" fill={palette.secondary.light} />
            </LegendStack>
          }
        />
        <Bar dataKey="Completed" stackId="hours" fill={palette.success.light} />
        <Bar dataKey="Booked" stackId="hours" fill={palette.secondary.dark} />
        <Bar
          dataKey="Remaining"
          stackId="hours"
          fill={palette.secondary.light}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BudgetHoursChart;
