import React from "react";

const FormatAddress = ({ value, inline = false }) => {
  const lines = [
    value?.addressLine1,
    value?.addressLine2,
    value?.townOrCity,
    value?.countyOrState,
    value?.postcode,
    value?.country?.label,
  ].filter((x) => !!x);

  if (lines.length === 0) return null;
  if (inline) return lines.join(", ");

  return lines.map((x, i) => (
    <React.Fragment key={x + i}>
      {i > 0 && <br />}
      {x}
    </React.Fragment>
  ));
};

export default FormatAddress;
