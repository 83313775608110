import NotificationsIcon from "@mui/icons-material/Notifications";
import { Stack, Typography, styled } from "@mui/material";
import { ExternalLink } from "@redriver/cinnamon-mui";
import UserIcon from "assets/icons/User";
import classNames from "classnames";
import { BasicTile } from "components/containers";
import { ActionLinkType, ActionPriority } from "constants/enums";
import {
  AppRoutes,
  ClientRoutes,
  DraftInvoiceRoutes,
  RelativeContractRoutes,
  RelativeEnquiryRoutes,
  RelativeEnquiryScopeRoutes,
  RelativeJobRoutes,
  RelativeResourceRoutes,
} from "constants/routes";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ActionTileMenu from "./ActionTileMenu";

const PriorityClass = {
  [ActionPriority.Low]: "low-priority",
  [ActionPriority.Medium]: "medium-priority",
  [ActionPriority.High]: "high-priority",
};

const getPriorityColours = (theme) => ({
  [ActionPriority.Low]: {
    main: theme.palette.success.main,
    background: theme.palette.background.success,
  },
  [ActionPriority.Medium]: {
    main: theme.palette.warning.main,
    background: theme.palette.background.warning,
  },
  [ActionPriority.High]: {
    main: theme.palette.error.main,
    background: theme.palette.background.error,
  },
});

const getLink = (type, id, parentEntityId) => {
  switch (type) {
    case ActionLinkType.Enquiry:
      return `${AppRoutes.Enquiries}/${RelativeEnquiryRoutes.Enquiry(id)}`;
    case ActionLinkType.Job:
      return `${AppRoutes.Jobs}/${RelativeJobRoutes.Job(id)}`;
    case ActionLinkType.Client:
      return ClientRoutes.Client(id);
    case ActionLinkType.Contract:
      return `${AppRoutes.Contracts}/${RelativeContractRoutes.Contract(id)}`;
    case ActionLinkType.Resource:
      return `${AppRoutes.Resources}/${RelativeResourceRoutes.Resource(id)}`;
    case ActionLinkType.EnquiryScope:
      return `${AppRoutes.Enquiries}/${RelativeEnquiryRoutes.Enquiry(
        parentEntityId
      )}/${RelativeEnquiryRoutes.Scope(id)}/${
        RelativeEnquiryScopeRoutes.Resources
      }`;
    case ActionLinkType.JobScope:
      return `${AppRoutes.Jobs}/${RelativeJobRoutes.Job(
        parentEntityId
      )}/${RelativeJobRoutes.Scope(id)}`;
    case ActionLinkType.DraftInvoice:
      return DraftInvoiceRoutes.DraftInvoice(id);
    default:
      return null;
  }
};

const ActionTile = ({ action, active, onClick, className, onUpdated }) => {
  const {
    title,
    assignedUsers,
    status,
    priority,
    linkedEntityType,
    linkedEntity,
    hasRead,
    parentEntityId,
  } = action;

  const [isUnread, setIsUnread] = useState(!hasRead);
  const link = getLink(
    linkedEntityType?.value,
    linkedEntity?.value,
    parentEntityId
  );

  return (
    <div className={classNames(className, PriorityClass[priority?.value])}>
      <BasicTile
        active={active}
        onClick={() => {
          onClick && onClick();
          setIsUnread(false);
        }}
        footer={
          <div className="row">
            <Typography className="linked-entity-placeholder" fontWeight={500}>
              {linkedEntity?.label}
            </Typography>
            {assignedUsers?.length > 0 && (
              <Stack direction="row" spacing={2} ml="auto">
                <UserIcon fontSize="small" />
                <div className="assigned-users">
                  {assignedUsers.map((u) => (
                    <Typography key={u.value}>{u.label}</Typography>
                  ))}
                </div>
              </Stack>
            )}
          </div>
        }
      >
        {isUnread && (
          <NotificationsIcon className="unread-icon" color="secondary" />
        )}
        <div className="row">
          <Typography fontWeight={500}>{title}</Typography>
          <Typography className="status">{status?.label}</Typography>
        </div>
        <div className="row">
          <Typography>{linkedEntityType?.label}</Typography>
        </div>
      </BasicTile>
      <div className="actions">
        <ActionTileMenu action={action} onUpdated={onUpdated} />
        <ExternalLink
          component={RouterLink}
          to={link}
          fontWeight={500}
          className="linked-entity"
        >
          {linkedEntity?.label}
        </ExternalLink>
      </div>
    </div>
  );
};

export default styled(ActionTile)(({ theme }) => {
  const priorityColours = getPriorityColours(theme);

  const priorityStyles = Object.values(ActionPriority).reduce(
    (acc, p) => ({
      ...acc,
      [`&.${PriorityClass[p]}`]: {
        ".MuiCardActions-root": {
          backgroundColor: priorityColours[p]?.background,
        },
        ".status": {
          color: priorityColours[p]?.main,
        },
      },
      [`&.${PriorityClass[p]} .MuiCard-root.active`]: {
        outline: `3px ${priorityColours[p]?.main} solid`,
      },
    }),
    {}
  );

  return {
    position: "relative",
    "> .actions > .CinActionMenu-root": {
      position: "absolute",
      top: `calc(${theme.spacing(2)} + 1.5rem)`,
      right: theme.spacing(1),
      "> .MuiIconButton-root": {
        padding: 4,
        "> .MuiSvgIcon-root": {
          fontSize: "1.5em",
        },
      },
    },
    "> .actions > .linked-entity": {
      position: "absolute",
      bottom: "0.75em",
      left: "calc(1rem + 30px)",
      lineHeight: 1,
    },
    ".linked-entity-placeholder": {
      whiteSpace: "nowrap",
      marginRight: "2rem",
      opacity: 0,
    },
    ".assigned-users": {
      display: "flex",
      flexWrap: "wrap",
      "> .MuiTypography-root": {
        marginRight: "1em",
      },
    },
    ".row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 30,
      "+ .row": {
        marginTop: "0.5rem",
      },
    },
    ".unread-icon": {
      position: "absolute",
      top: theme.spacing(2),
      left: theme.spacing(1),
    },
    ...priorityStyles,
  };
});
