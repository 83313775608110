import { Stack, Typography } from "@mui/material";
import { useAuthClaims } from "@redriver/cinnamon-mui";
import { UserDetails, PasswordChange, UserSignature } from "features/System";
import {
  HeaderContainer,
  ContentContainer,
  ContentPanel,
} from "components/containers";
import { AppPageContent } from "components/pages";
import { UserType } from "constants/enums";

const AccountPage = () => {
  const { ssoProvider, userType } = useAuthClaims();
  return (
    <AppPageContent>
      <HeaderContainer variant="shadow">
        <Typography variant="h5">My Account</Typography>
      </HeaderContainer>
      <ContentContainer>
        <Stack direction="row" alignItems="flex-start" spacing={3}>
          <ContentPanel title="Account Details">
            <UserDetails />
          </ContentPanel>
          {userType === UserType.Internal && <UserSignature />}
          {!ssoProvider && (
            <ContentPanel title="Change Password" sx={{ minWidth: "25rem" }}>
              <PasswordChange />
            </ContentPanel>
          )}
        </Stack>
      </ContentContainer>
    </AppPageContent>
  );
};

export default AccountPage;
