import { Button } from "@mui/material";
import { ModalBuilder, Modal, Form, FormFile } from "@redriver/cinnamon-mui";
import { FileSizeError } from "components/forms";
import { uploadDocumentVersion } from "./actions";

const UploadDocumentVersion = ({
  as: As = Button,
  onUploaded,
  documentId,
  ...props
}) => (
  <ModalBuilder
    withForm
    submitAction={uploadDocumentVersion}
    submitParams={documentId}
    onSubmitted={onUploaded}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Upload {...modalProps} header="Upload New Version">
        <Form {...formProps}>
          <FormFile field="file" dropzone fluid />
          <FileSizeError field="file" maxSizeMB={100} />
        </Form>
      </Modal.Upload>
    )}
  />
);

export default UploadDocumentVersion;
