import { Stack } from "@mui/material";

const LegendStack = ({
  iconSize,
  layout,
  align,
  verticalAlign,
  chartWidth,
  chartHeight,
  onBBoxUpdate,
  wrapperStyle,
  content,
  payload,
  ...props
}) => <Stack {...props} />;

export default LegendStack;
