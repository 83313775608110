import { IconButton } from "@mui/material";
import { ActionMenu } from "@redriver/cinnamon-mui";
import { ActionStatus } from "constants/enums";
import CompleteAction from "./CompleteAction";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const ActionTileMenu = ({ action, onUpdated }) => {
  const access = {
    canComplete: action.status?.value !== ActionStatus.Complete,
  };

  if (!Object.values(access).some((x) => x)) return null;

  return (
    <ActionMenu
      triggerComponent={IconButton}
      triggerProps={{
        children: <MoreHorizIcon />,
      }}
    >
      {access.canComplete && (
        <CompleteAction
          as={ActionMenu.Item}
          actionId={action.id}
          onCompleted={onUpdated}
        >
          Complete
        </CompleteAction>
      )}
    </ActionMenu>
  );
};

export default ActionTileMenu;
