import { produce } from "immer";
import { useState } from "react";

export const useDataGridColumns = (initialColumns) => {
  const [columns, setColumns] = useState([]);
  const [gridColumns, setGridColumns] = useState(initialColumns);

  const reorderColumns = (e) => {
    const orderedColumns = e.columns
      .sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      })
      .map((o) => o.field);
    setColumns(orderedColumns);
  };

  const onColumnSelectionChange = (field, visible) => {
    const newState = produce(gridColumns, (draft) => {
      const item = draft.find((x) => x.field == field);
      if (item) {
        item.show = visible;
      }
    });
    setGridColumns(newState);
    //reorder for export
    let orderedColumns = newState
      .filter((c) => c.show)
      .sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      })
      .map((o) => o.field);
    setColumns(orderedColumns);
  };

  return {
    columns,
    gridColumns,
    onColumnSelectionChange,
    reorderColumns,
  };
};

export const useDataGridPagination = () => {
  const [dataState, setDataState] = useState({
    take: 25,
    skip: 0,
  });
  const pagerSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [25, 50, 100, "All"],
    pageSizeValue: pageSizeValue,
    previousNext: true,
  };
  const [maxPage, setMaxPage] = useState(1000);
  const [page, setPage] = useState(dataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? maxPage : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
    setDataState({
      skip: event.page.skip,
      take: take,
    });
  };
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  return {
    dataState,
    dataStateChange,
    setMaxPage,
    page,
    pageChange,
    pagerSettings,
  };
};
