import { ActionMenu } from "@redriver/cinnamon-mui";
import EditAction from "./EditAction";
import AddRemark from "./AddRemark";

const EditActionMenu = ({ fromJob, action, onUpdated, onRemarkAdded }) => (
  <ActionMenu triggerProps={{ children: "Edit" }}>
    <EditAction
      fromJob={fromJob}
      as={ActionMenu.Item}
      actionId={action.id}
      onUpdated={onUpdated}
    >
      Edit Action
    </EditAction>
    <AddRemark
      as={ActionMenu.Item}
      actionId={action.id}
      onAdded={onRemarkAdded}
    >
      Add Remark
    </AddRemark>
  </ActionMenu>
);

export default EditActionMenu;
