import classNames from "classnames";
import { Typography, styled } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const LegendItem = ({ value, fill, suffix, className }) => (
  <div className={classNames("legend-item", className)}>
    <CircleIcon fontSize="small" htmlColor={fill} />
    <Typography className="value">{value}</Typography>
    {suffix && <Typography className="suffix">{suffix}</Typography>}
  </div>
);

export default styled(LegendItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "> .value": {
    fontWeight: "500",
    marginLeft: theme.spacing(1),
  },
  "> .suffix": {
    marginLeft: theme.spacing(1),
  },
}));
